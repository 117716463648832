<template>
  <div>
    <Card :parent_id="parent_id">
      <p class="ti">内心独白</p>
    </Card>

    <div class="monologue">
      <p>介绍一下自己，描述一下理想的伴侣，说说你对婚姻的期望</p>
       <!-- maxlength="1500" -->
      <textarea
        id="text"
        v-model="rise"
        @input="riseInput"
        maxlength="1000"
        placeholder="说出你的故事……"
      />
      <span class="number">{{ extent }}/1000</span>
    </div>

    <div class="tips">
      <!-- <p>请填写内心独白</p> -->
      <p></p>
      <p><i class="el-icon-warning-outline"></i> 小提示:</p>
      <p>
        1.为了遵守国家法规，保障用户个人信息安全，内心独白中请勿出现QQ、微信、微博、电话号码等联系方式以及网址、广告、色情、诋毁或其他不健康的内容。
      </p>
      <p>2.若文字中出现不符合标准的内容，我们将予以删除，请认真填写。</p>
    </div>

    <!-- 按钮 -->
    <Btn style="margin-top: 56px">
      <div class="continue" @click="bcjx">保存并继续</div>
      <div class="skip" @click="tg">跳过</div>
    </Btn>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Btn from "@/components/Card/Btn.vue";
import local from "@/api/common/local.js";
// import { infoUpdate } from "@/api/login/login.js"; //infosub
import { infocur } from "@/api/login/login.js";
import { subTk,approveUpdate} from "@/api/member/member.js";
// import { sendSms, subTk,  } from "@/api/member/member.js";
export default {
  data() {
    return {
      extent: 0,
      rise: "",
      parent_id: "",
    };
  },
  components: {
    Card,
    Btn,
  },
  created() {
    this.cs();
    this.tkL();
  },
  methods: {
    async cs() {
      let a = local.get("access_token");
      // const { code, data } = await infosub(a);
      // if (code == 0) {
      //   // introduce
      //   console.log("成功", data);
      //   this.rise = data.user.introduce;
      // }
      const { code, data } = await infocur(a);
      if (code == 0) {
        console.log("成功", data);
        // this.rise = data.user.introduce;
        if(data.user.introduce){
          this.rise = data.user.introduce
            this.extent = this.rise.length;
        }else{
          this.rise = ''
            this.extent = 0;
        }
        this.parent_id = data.user.id;
      
      }
    },
    riseInput() {
      // let textVal =
      this.extent = this.rise.length; //输入的长度
    },
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    async bcjx() {

      
      //判断内心独白是否为空
      var reg = /^\s*$/g;
      if (this.rise == "" || reg.test(this.rise) || this.rise == null) {
        this.$message({
          message: "内心独白不能为空",
          type: "error",
          offset: 100,
        });
      } else {
        this.tkL()
        let a = {
          introduce: this.rise,
        };
        const { code } = await approveUpdate(a);
        if (code == 0) {
          this.$message({
            message: "修改成功",
            type: "success",
            offset: 100,
          });
          this.tkL();
          setTimeout(() => {
            //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
            this.$router.push({
              path: `/n/user/_interest`,
            });
            //延迟时间：3秒
          }, 0);
        }
      }
      //保存调接口
      // let a = {
      //   introduce: this.rise,
      // };
      // const { code } = await infoUpdate(a);
      // if (code == 0) {
      //   this.$message({
      //     message: "修改成功",
      //     type: "success",
      //     offset:100
      //   });
      //   this.tkL();
      //   setTimeout(() => {
      //     //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
      //     this.$router.push({
      //       path: `/n/user/_interest`,
      //     });
      //     //延迟时间：3秒
      //   }, 0);
      // }
    },
    tg() {
      this.$router.push({
        path: `/n/user/_interest`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.monologue {
  position: relative;
  width: 880px;
  p {
    margin-top: 32px;
  }
  #text {
    margin-top: 28px;
    padding: 12px 20px;
    width: 574px;
    height: 238px;
    box-sizing: border-box;
    background: #f5f5f5;
    border-radius: 2px;
    /*去掉右下角的斜杠，不允许文本框缩放*/
    resize: none;
    /*将边框去掉*/
    border: none;
    border-radius: 2px;
    /*设置字体大小*/
    font-size: 14px;
    /* placeholder位置 提示靠左靠右居中等 */
    text-align: left;
    /* 其他样式还有不少，没有用就没查，需要可自行查看 */
  }
  textarea[id="text"]::-webkit-input-placeholder {
    color: #adadad;
    font-size: 14px;
  }
  textarea[id="text"]:-moz-placeholder {
    color: #adadad;
    font-size: 14px;
  }
  textarea[id="text"]::-moz-placeholder {
    color: #adadad;
    font-size: 14px;
  }
  textarea[id="text"]:-ms-input-placeholder {
    color: #adadad;
    font-size: 14px;
  }
  .number {
    position: absolute;
    bottom: 12px;
    // bottom: 14px;
    right: 340px;
    // right: 120px;
  }
}
.tips {
  width: 520px;
  line-height: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  p:nth-child(1) {
    font-size: 12px;
    font-weight: 400;
    // color: #ff5c5c;
    line-height: 17px;
    margin-top: 12px;
  }
  p:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    margin-top: 18px;
    margin-bottom: 6px;
    color: #333333;
    line-height: 20px;
  }
  p:nth-child(3),
  p:nth-child(4) {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 17px;
  }
}
</style>